import React, { Component, useState } from 'react';
import './style.css';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Rkv from './assets/s.svg';

export function LoadingScreen() {
  return (
      <div>
         <div className="Loading-header">
         <Box id="loaderBox" sx={{ width: '20%', display: 'block' }}>
            <img id="rkv" src={Rkv} alt="rkv" style={{width: "80%", marginBottom: "10%"}} />
            <LinearProgress />
         </Box>
         </div>
      </div>
   )
}
