import React, { Component } from 'react';
import './style.css';
import { Link } from "react-router-dom";
import Astronaut from './assets/astronaut.svg';
import Planet from './assets/planet.svg';
import particlesJs from './assets/404_particle';
import Particles from "react-tsparticles";

class NotFoundPage extends Component {

  constructor(props) {
    super(props);

    this.particlesInit = this.particlesInit.bind(this);
    this.particlesLoaded = this.particlesLoaded.bind(this);
  }

  particlesInit(main) {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  }

  particlesLoaded(container) {
    console.log(container);
  }

  render() {

    return (
      <div>
        
        <div className="permission_denied">
          <Particles
            id="tsparticles"
            init={this.particlesInit}
            loaded={this.particlesLoaded}
            options={particlesJs}
          />
          <div className="denied__wrapper">
              <h1 className="no-margin">404</h1>
              <h3>LOST IN <span>SPACE</span> notes.rkv.one? Hmm, looks like that page doesn't exist.</h3>
              <img id="astronaut" src={Astronaut} alt="Astronaut"/>
              <img id="planet" src={Planet} alt="Planet" />
            <Link to="/"><button className="denied__link">Go Home</button></Link>
          </div>
        </div>
      </div>
    )
    
  }
}

export default NotFoundPage;
