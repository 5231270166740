import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from '../styles/global-styles';

import Loading from './pages/Loading';
import { HomePage } from './pages/HomePage/Loadable';
import NotFoundPage from './pages/NotFoundPage/index';

export function App() {
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s"
        defaultTitle="Rkv Notes"
      >
        <meta name="description" content="Access your notes from anywhere, anytime." />
      </Helmet>

      <Routes>
        <Route path={process.env.PUBLIC_URL + '/'} element={<Loading />} />
        {/* <Route path='/mynotes' element={<HomePage />} /> */}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
