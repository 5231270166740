import React, { Component } from 'react';
import SignIn from './../HomePage/components/SignIn';
import { LoadingScreen } from './LoadingScreen';
import { HomePage } from './../HomePage/Loadable';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAkKX-qlUeEax6Qp5PeO7qPoBVQ01RzvYI',
  authDomain: 'rkv-one.firebaseapp.com',
  projectId: 'rkv-one',
  storageBucket: 'rkv-one.appspot.com',
  messagingSenderId: '865777080431',
  appId: '1:865777080431:web:3574dd2e16ac5912c5959a',
  measurementId: 'G-Z7PWLJN76V',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

class Loading extends Component {

  state = {
    redirect: false,
    isUser: null
  }


  componentDidMount() {
    setTimeout(() => {
      this.setState({ redirect: true })
    }, 3000);

    onAuthStateChanged(auth, user => {
      if (user) {
        this.setState({ isUser: true });
      } else {
        this.setState({ isUser: false });
      };
    });
  }

  render() {
    if (this.state.redirect && this.state.isUser) {
      //return <Navigate to='/mynotes' />
      return <HomePage />
    } else if (this.state.redirect && !this.state.isUser) {
      return <SignIn />
    }
    else {
      return <LoadingScreen />
    }
  }
}

export default Loading;
