import { PayloadAction } from '@reduxjs/toolkit';
import { User } from './../../../../types/User';
import { createSlice } from './../../../../utils/@reduxjs/toolkit';
import { useInjectReducer } from './../../../../utils/redux-injectors';
import { AuthState } from './types';

export const initialState: AuthState = {
  isSignIn: false,
  user: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSignIn(state, action: PayloadAction<User>) {
      const authUser = action.payload;
      state.isSignIn = true;
      state.user = authUser;
    },
    authSignOut(state, action: PayloadAction<boolean>) {
      const signOut = action.payload;
      state.isSignIn = false;
      state.user = null;
    },
  },
});

export const { actions: authActions, reducer } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
