import React, { Component } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";

import {
  selectIsSignIn,
  selectUser,
} from './../slice/selectors';
import { useAuthSlice } from './../slice';
import { User } from './../../../../types/User';
import GoogleSigninBtn from './../assets/btn_google_signin_dark_normal_web.png';


const firebaseConfig = {
  apiKey: 'AIzaSyAkKX-qlUeEax6Qp5PeO7qPoBVQ01RzvYI',
  authDomain: 'rkv-one.firebaseapp.com',
  projectId: 'rkv-one',
  storageBucket: 'rkv-one.appspot.com',
  messagingSenderId: '865777080431',
  appId: '1:865777080431:web:3574dd2e16ac5912c5959a',
  measurementId: 'G-Z7PWLJN76V',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function SignIn() {
  
  let navigate = useNavigate();
  const { actions } = useAuthSlice();

  const isSignIn = useSelector(selectIsSignIn);
  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const onClickSignIn = () => {
    // Google provider object is created here.
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    // using the object we will authenticate the user.
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential =
          GoogleAuthProvider.credentialFromResult(result);
        var token;
        if (credential !== null) {
          token = credential.accessToken || null;
        }

        navigate("/");

        // The signed-in user info.
        const user = result.user;
        if (user.email !== null && user.displayName !== null && user.photoURL !== null) {
          const userData: User = {
            uid: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
            displayName: user.displayName,
            photoURL: user.photoURL
          };
          dispatch(actions.authSignIn(userData));
        };
      })
      .catch(error => {
        navigate("/");
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, email, credential);
        // ...
      });
  };

  const imgStyle = {
    
  };
  
  return (
    <div>
      <img src={GoogleSigninBtn} style={{ marginLeft: "auto", marginRight: "auto", display: "block", marginTop: "40vh", width: "200px", cursor: "pointer"}} onClick={onClickSignIn} alt="Google Signin" />
    </div>
  );
}
export default SignIn;
