import { createSelector } from '@reduxjs/toolkit';

import { RootState } from './../../../../types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.auth || initialState;

export const selectIsSignIn = createSelector(
  [selectDomain],
  authState => authState.isSignIn,
);

export const selectUser = createSelector(
  [selectDomain],
  authState => authState.user,
);